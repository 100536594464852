<template>
    <v-icon
        v-if="isLoggedIn"
        :icon="mdiAccountCircle"
        :size="size"
    ></v-icon>
    <v-icon
        v-else
        :icon="mdiAccountCircleOutline"
        :size="size"
    ></v-icon>
</template>

<script lang="ts" setup>
    import { mdiAccountCircle, mdiAccountCircleOutline } from '@mdi/js';
    import { useCustomAuth } from '~/composables';

    const { isLoggedIn } = useCustomAuth();

    withDefaults(
        defineProps<{
            size?: Undefinable<number | string>;
        }>(),
        {
            size: undefined
        }
    );
</script>

<style lang="scss"></style>
